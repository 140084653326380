import { API_STATUS } from "../../../../constants/commonConstants";
import DashboardManager from "../../../dashboard/managers/dashboardManager";
import { Box, Grid } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { Link } from "react-router-dom";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import {
  BreadCrumbDataInvestorInformation,
  caDocStatus,
  faasNonFaasTypes,
} from "../../constants/dashboardConstants";

const columns = [
  {
    field: "id",
    headerName: "S.No",
    align: "center",
    flex: 2,
    minWidth: 120,
  },
  {
    field: "name",
    headerName: "Investor Name",
    headerAlign: "left",
    flex: 2,
    minWidth: 150,
  },
  {
    field: "email",
    headerName: "Investor Email",
    headerAlign: "left",
    flex: 2,
    minWidth: 300,
  },
  {
    field: "kyc_kra_check",
    headerName: "KYC && KRA Check",
    headerAlign: "left",
    flex: 2,
    minWidth: 200,
  },
  {
    field: "date",
    headerName: "Last Modified",
    align: "center",
    flex: 2,
    minWidth: 150,
  },
  {
    field: "ca_doc_sent",
    headerName: "Ca Doc Sent",
    headerAlign: "left",
    flex: 2,
    minWidth: 150,
  },
  {
    field: "ca_doc_sent_date",
    headerName: "Ca Doc Sent Date",
    align: "center",
    flex: 2,
    minWidth: 200,
  },
  {
    field: "faas_non_faas",
    headerName: "Faas/Non-Faas",
    headerAlign: "left",
    flex: 2,
    minWidth: 150,
  },
  {
    field: "view_profile",
    headerName: "View Profile",
    headerAlign: "left",
    minWidth: 180,
    renderCell: (params: any) => {
      const actionData = params.row.action;
      return (
        <Link
          style={{ textDecoration: "none" }}
          to={`/user/show_user/${params.row?.user_id}/`}
        >
          {params?.row?.name}{" "}
        </Link>
      );
    },
  },
];

export default function InvestorInformation() {
  const authContext = useContext(AuthContext);
  const dashboardManager = new DashboardManager(authContext);
  const [value, setValue] = useState("");
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [selectedCaDocStatus, setSelectedCaDocStatus] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  type GetDataParams = {
    email?: any;
    page?: number;
    ca_doc_sent_status?: { value: any };
    profile_type?: { value: any };
  };

  const getData = async ({
    email = "",
    page = 1,
    ca_doc_sent_status = { value: null },
    profile_type = { value: null },
  }: GetDataParams = {}) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data = await dashboardManager.getInvestorInformation(email, page, ca_doc_sent_status?.value, profile_type?.value);
      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    getData({email: value});
  };

  const filterData = (type: any, value: any) => {
    if(type == "ca_doc_sent_status"){
      setSelectedCaDocStatus(value);
      getData({ca_doc_sent_status: value, profile_type: selectedType});
    }else{
      setSelectedType(value);
      getData({ca_doc_sent_status: selectedCaDocStatus, profile_type: value});
    }
  }

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataInvestorInformation?.steps}
            pageHeader={BreadCrumbDataInvestorInformation.pageHeader}
            stepKey={BreadCrumbDataInvestorInformation.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box width={"150px"}>
            <CoreDropdown
              label="CA Doc Sent"
              options={caDocStatus}
              value={selectedCaDocStatus}
              onClickMenuItem={(val: any) => {
                filterData('ca_doc_sent_status', val)
              }}
            />
          </Box>
          <Box width={"150px"}>
            <CoreDropdown
              label="Faas/Non-Faas"
              options={faasNonFaasTypes}
              value={selectedType}
              onClickMenuItem={(val: any) => {
                filterData('profile_type', val)
              }}
            />
          </Box>
          <Box>
            <CustomTextField
              label={"Search by Investor Email"}
              width={"300px"}
              InputLabelProps={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>
          <ActionButton
          onClick={handleSearchClick}
          >
            Search
          </ActionButton>
        </Grid>
      </Grid>

      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <TeComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
    </Box>
  );
}
