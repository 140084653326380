import { Box, Grid, Stack } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { HorizontalTabs } from "@trica-equity/ui-library.tabs.horizontal-tabs";
import { useState } from "react";
import {
  DealTypeOptions,
  FinalScreeningTabs,
  SortByDocOptions,
} from "../../constants/FinalScreeningConstant";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import StartupManagement from "../../managers/startupManager";
import { toast } from "react-toastify";
import { useEffect } from "react";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { Check } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { SortByOptions } from "../../constants/InitialScreeningConstant";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Final Screening",
    navigationButton: false,
  },
};

export default function addNewInstitution() {
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [selectedTab, setSelectedTab] = useState(FinalScreeningTabs[0].value);
  const [sortBy, setSortBy] = useState<any>({});
  const [sortByDoc, setSortByDoc] = useState<any>({});
  const [dealType, setDealType] = useState<any>({});
  const [sector, setSector] = useState<any>({});
  const [sectorOption, setSectorOption] = useState([]);
  const [sectorPoc, setSectorPoc] = useState<any>({});
  const [name, setName] = useState("");
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const getSectorOption = async () => {
    try {
      const result = await userManagementManager.getLookupData(
        "",
        "horizontal_sector"
      );
      let options = result?.results?.map((item: any) => ({
        label: item?.text,
        value: item?.id,
      }));
      setSectorOption(options);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const getCaDocStatus = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await startupManagement.getFinalScreeningDocs(
          selectedTab,
          page,
          sortBy?.value ?? "",
          sortByDoc?.value ?? "",
          dealType?.value ?? "",
          sector?.value ?? "",
          sectorPoc?.value ?? "",
          name ?? ""
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleAceept = async (id: string) => {
    try {
      const result = await startupManagement.finalScreeningAction(id, true);

      toast.success(result?.detail);
      getCaDocStatus();
    } catch (e: any) {
      toast.error(e?.detail);
    }
  };

  const handleReject = async (id: string) => {
    try {
      const result = await startupManagement.finalScreeningAction(id, false);
      toast.success(result?.detail);
      getCaDocStatus();
    } catch (e: any) {
      toast.error(e);
      toast.error(e?.detail);
    }
  };

  const AwaitingColumns = [
    {
      field: "serial_no",
      headerName: "S.NO",
      sortable: false,
      flex: 1,
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 180,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link to={`/startup/show-startup/${params.row?.id}/`}>
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "sector",
      headerName: "Startup's Sector",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "approved_from_initial",
      headerName: "Approved From Initial",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "approved_from_initial_by",
      headerName: "Approved From Initial By",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "mou_uploaded",
      headerName: "MOU Uploaded",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (params?.row?.mou_uploaded ? <Check /> : ""),
    },
    {
      field: "is_faas",
      headerName: "Deal Type",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => (params?.row?.is_faas ? "FaaS" : "Non-FaaS"),
    },
    // {
    //   field: "profile_complete",
    //   headerName: "Profile Complete",
    //   flex: 1,
    //   minWidth: 100
    // },
    {
      field: "current_tab",
      headerName: "Current Tab",
      flex: 1,
      minWidth: 250,
    },

    {
      field: "accept",
      headerName: "Accept",
      flex: 3,
      minWidth: 150,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("success", 500),
              borderColor: getColor("success", 500),
            }}
            onClick={() => {
              handleAceept(params.row?.id);
            }}
          >
            Accept
          </OutlinedButton>
        );
      },
    },
    {
      field: "reject",
      headerName: "Reject",
      flex: 3,
      minWidth: 150,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            onClick={() => handleReject(params?.row?.id)}
            customStyles={{
              color: getColor("error", 500),
              borderColor: getColor("error", 500),
            }}
          >
            Reject
          </OutlinedButton>
        );
      },
    },
  ];

  const ApprovedColumns = [
    {
      field: "serial_no",
      headerName: "S.NO",
      sortable: false,
      flex: 1,
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 180,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link to={`/startup/show-startup/${params.row?.id}/`}>
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "sector",
      headerName: "Startup's Sector",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "approved_from_initial",
      headerName: "Approved On",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "approved_from_initial_by",
      headerName: "Approved By",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "mou_uploaded",
      headerName: "MOU Uploaded",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (params?.row?.mou_uploaded ? <Check /> : ""),
    },
    {
      field: "startup_blurb_uploaded",
      headerName: "Startup Blurb",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) =>
        params?.row?.startup_blurb_uploaded ? <Check /> : "",
    },
    // {
    //   field: "profile_complete",
    //   headerName: "Profile Complete",
    //   flex: 1,
    //   minWidth: 100
    // },
    {
      field: "current_tab",
      headerName: "Current Tab",
      flex: 1,
      minWidth: 250,
    },
  ];

  const RejectedColumns = [
    {
      field: "serial_no",
      headerName: "S.NO",
      sortable: false,
      flex: 1,
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link to={`/startup/show-startup/${params.row?.id}/`}>
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "sector",
      headerName: "Startup's Sector",
      flex: 2,
      minWidth: 300,
    },
    {
      field: "approved_from_initial",
      headerName: "Rejected On",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "approved_from_initial_by",
      headerName: "Rejected By",
      flex: 1,
      minWidth: 200,
    },

    {
      field: "mou_uploaded",
      headerName: "MOU Uploaded",
      flex: 2,
      minWidth: 100,
      renderCell: (params: any) => (params?.row?.mou_uploaded ? <Check /> : ""),
    },
    {
      field: "startup_blurb_uploaded",
      headerName: "Startup Blurb",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) =>
        params?.row?.startup_blurb_uploaded ? <Check /> : "",
    },
    // {
    //   field: "profile_complete",
    //   headerName: "Profile Complete",
    //   flex: 1,
    //   minWidth: 100
    // },
    {
      field: "current_tab",
      headerName: "Current Tab",
      flex: 1,
      minWidth: 250,
    },

    {
      field: "action",
      headerName: "",
      flex: 3,
      minWidth: 250,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("success", 500),
              borderColor: getColor("success", 500),
            }}
          >
            Move to Awaiting
          </OutlinedButton>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const getColumnData = (tab: string) => {
    switch (tab) {
      case "awaiting":
        return AwaitingColumns;
      case "approved":
        return ApprovedColumns;
      case "rejected":
        return RejectedColumns;
      default:
        return AwaitingColumns;
    }
  };

  useEffect(() => {
    getCaDocStatus();
  }, [page, selectedTab]);
  useEffect(() => {
    getSectorOption();
  }, []);

  return (
    <Box p={2}>
      <Grid container spacing={2} mb={"1rem"}>
        <Grid item xs={2}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid
          item
          xs={10}
          display="flex"
          gap={2}
          justifyContent="end"
          flexWrap="wrap"
        >
          <Box width={"120px"}>
            <CoreDropdown
              label="Sort by"
              options={SortByOptions}
              value={sortBy}
              onClickMenuItem={(value: any) => setSortBy(value)}
            />
          </Box>
          <Box width={"180px"}>
            <CoreDropdown
              label="Search by Sector"
              options={sectorOption}
              value={sector}
              onClickMenuItem={(value: any) => setSector(value)}
              clearable
            />
          </Box>
          <Box width={"180px"}>
            <CoreDropdown
              label="Sort By Documents"
              options={SortByDocOptions}
              value={sortByDoc}
              onClickMenuItem={(value: any) => setSortByDoc(value)}
              clearable
            />
          </Box>
          <Box width={"180px"}>
            <CoreDropdown
              label="Sort by Sector PoC"
              options={[]}
              value={sectorPoc}
              onClickMenuItem={(value: any) => setSectorPoc(value)}
              clearable
            />
          </Box>

          <Box width={"180px"}>
            <CoreDropdown
              label="Sort By Deal Type"
              options={DealTypeOptions}
              value={dealType}
              onClickMenuItem={(value: any) => setDealType(value)}
              clearable
            />
          </Box>
          <Stack direction="row" gap={2}>
            <Box width="20vw">
              <CustomTextField
                value={name}
                onChange={(val: any) => setName(val)}
                label="Search By Startup Name"
              />
            </Box>{" "}
            <ActionButton onClick={getCaDocStatus}>Search</ActionButton>
          </Stack>
        </Grid>
      </Grid>
      <HorizontalTabs
        tabs={FinalScreeningTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <TeComplexTable
            rows={formattedData}
            columns={getColumnData(selectedTab)}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
    </Box>
  );
}
