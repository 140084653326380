import {
    getInvestorInformation,
    getNomineeInformation,
    getDematInformation
    
  } from "../services/dashboardServices";
  import { AuthContextProps, UserAuthInfo } from "../../../contexts/authContext";
  
  export default class DashboardManager {
    private userData: UserAuthInfo;
  
    constructor(AuthContext: AuthContextProps) {
      this.userData = AuthContext.authState;
    }
    async getInvestorInformation(email: string, page: number, ca_doc_sent_status: string, profile_type: string) {
      try {
          let result = await getInvestorInformation(email, page, ca_doc_sent_status, profile_type);
          // let result = {
          //   "count": 2,
          //   "next": "https://api.staging.letsventure.com/v1/admin/user_management/new_verify_investor/?mode=awaiting&page=2&search_text=",
          //   "previous": null,
          //   "results": [
          //     {
          //       "id": 3416,
          //       "date": "2023-08-07",
          //       "name": "Kashee Kushwaha",
          //       "email": "kasheeram.kushwaha+aug07@letsventure.com",
          //       "mobile": "+919179807380",
          //       "linkedin_profile": "https://LinkedIn.com/in/kashee",
          //       "city": "Bengaluru, India",
          //       "onboard": "angel_investor",
          //       "profile_type": "professional",
          //       "kyc_kra_check": "No",
          //       "ca_doc_sent": "Not Sent",
          //       "ca_doc_sent_date": "2023-08-07",
          //       "faas_non_faas": "Faas",
          //       "view_profile": "Avinash singh",
          //       "join_user_info": {
          //         "invest_today": null,
          //         "investment_budget_per_year": 0,
          //         "expertise_sectors": [
                    
          //         ],
          //         "no_of_investments": null,
          //         "individual_statement_fresher": true,
          //         "individual_statement_intermediate": false,
          //         "individual_statement_experience": false,
          //         "none_of_the_above": false,
          //         "experience": null,
          //         "institution_name": null,
          //         "designation": null,
          //         "institution_url": null,
          //         "institution_assets_worth": null,
          //         "net_worth": 0,
          //         "called_investor": null
          //       }
          //     },
          //     {
          //       "id": 3279,
          //       "date": "2023-05-25",
          //       "name": "Avinash Singh",
          //       "email": "avinash.singh+may258@letsventure.com",
          //       "mobile": "+919584754016",
          //       "linkedin_profile": "https://www.linkedin.com/in/avinash-pratap-singh-13b37a147/",
          //       "city": "Gwalior, India",
          //       "onboard": "angel_investor",
          //       "profile_type": "business_owner",
          //       "kyc_kra_check": "No",
          //       "ca_doc_sent": "Not Sent",
          //       "ca_doc_sent_date": "2023-08-07",
          //       "faas_non_faas": "Faas",
          //       "view_profile": "Avinash singh",
          //       "join_user_info": {
          //         "invest_today": null,
          //         "investment_budget_per_year": 0,
          //         "expertise_sectors": [
                    
          //         ],
          //         "no_of_investments": null,
          //         "individual_statement_fresher": false,
          //         "individual_statement_intermediate": false,
          //         "individual_statement_experience": false,
          //         "none_of_the_above": true,
          //         "experience": null,
          //         "institution_name": null,
          //         "designation": null,
          //         "institution_url": null,
          //         "institution_assets_worth": null,
          //         "net_worth": 0,
          //         "called_investor": null
          //       }
          //     },
              
          //   ]
          // }
          result = result.data
          let formattedData = result?.results?.map((item: any) => ({
            ...item,
            // date: item.approval_date,
            // manager: item.relationship_manager,
            // phone_no: item.users_phone,
            location: item.city,
            onboarding_as: item.onboard,
            // type: item.investor_type,
          }));
          return { formattedData, count: result?.count };
        } catch (error) {
          throw error;
        }
      }

    async getNomineeInformation(email: string, page: number) {
      try {
        let result = await getNomineeInformation(email, page);
        result = result.data
        // let result = {
        //   "count": 2,
        //   "next": "https://api.staging.letsventure.com/v1/admin/user_management/new_verify_investor/?mode=awaiting&page=2&search_text=",
        //   "previous": null,
        //   "results": [
        //     {
        //       "id": 3416,
        //       "date": "2023-08-07",
        //       "name": "Kashee Kushwaha",
        //       "nominee_name": "Anay",
        //       "email": "kasheeram.kushwaha+aug07@letsventure.com",
        //       "mobile": "+919179807380",
        //       "linkedin_profile": "https://LinkedIn.com/in/kashee",
        //       "city": "Bengaluru, India",
        //       "onboard": "angel_investor",
        //       "profile_type": "professional",
        //       "kyc_kra_check": "No",
        //       "nominee_relation_Type": "Child",
        //       "ca_doc_sent_date": "2023-08-07",
        //       "faas_non_faas": "Faas",
        //       "view_profile": "Avinash singh",
        //       "join_user_info": {
        //         "invest_today": null,
        //         "investment_budget_per_year": 0,
        //         "expertise_sectors": [
                  
        //         ],
        //         "no_of_investments": null,
        //         "individual_statement_fresher": true,
        //         "individual_statement_intermediate": false,
        //         "individual_statement_experience": false,
        //         "none_of_the_above": false,
        //         "experience": null,
        //         "institution_name": null,
        //         "designation": null,
        //         "institution_url": null,
        //         "institution_assets_worth": null,
        //         "net_worth": 0,
        //         "called_investor": null
        //       }
        //     },
        //     {
        //       "id": 3279,
        //       "date": "2023-05-25",
        //       "name": "Avinash Singh",
        //       "nominee_name": "Anay",
        //       "email": "avinash.singh+may258@letsventure.com",
        //       "mobile": "+919584754016",
        //       "linkedin_profile": "https://www.linkedin.com/in/avinash-pratap-singh-13b37a147/",
        //       "city": "Gwalior, India",
        //       "onboard": "angel_investor",
        //       "profile_type": "business_owner",
        //       "kyc_kra_check": "No",
        //       "nominee_relation_Type": "Child",
        //       "ca_doc_sent_date": "2023-08-07",
        //       "faas_non_faas": "Faas",
        //       "view_profile": "Avinash singh",
        //       "join_user_info": {
        //         "invest_today": null,
        //         "investment_budget_per_year": 0,
        //         "expertise_sectors": [
                  
        //         ],
        //         "no_of_investments": null,
        //         "individual_statement_fresher": false,
        //         "individual_statement_intermediate": false,
        //         "individual_statement_experience": false,
        //         "none_of_the_above": true,
        //         "experience": null,
        //         "institution_name": null,
        //         "designation": null,
        //         "institution_url": null,
        //         "institution_assets_worth": null,
        //         "net_worth": 0,
        //         "called_investor": null
        //       }
        //     },
            
        //   ]
        // }
        let formattedData = result?.results?.map((item: any) => ({
          ...item,
          // date: item.approval_date,
          // manager: item.relationship_manager,
          // phone_no: item.users_phone,
          location: item.city,
          onboarding_as: item.onboard,
          // type: item.investor_type,
        }));
        return { formattedData, count: result?.count };
      } catch (error) {
        throw error;
      }
    }

    async getDematInformation(email: string, page: number, tab: string) {
      try {
        let result = await getDematInformation(email, page, tab);
        result = result.data
        // let result = {
        //   "count": 2,
        //   "next": "https://api.staging.letsventure.com/v1/admin/user_management/new_verify_investor/?mode=awaiting&page=2&search_text=",
        //   "previous": null,
        //   "results": [
        //     {
        //       "id": 3416,
        //       "demate_account_number": "3847563746589765",
        //       "name": "Kashee Kushwaha",
        //       "nominee_name": "Anay",
        //       "email": "kasheeram.kushwaha+aug07@letsventure.com",
        //       "mobile": "+919179807380",
        //       "linkedin_profile": "https://LinkedIn.com/in/kashee",
        //       "city": "Bengaluru, India",
        //       "onboard": "angel_investor",
        //       "profile_type": "professional",
        //       "kyc_kra_check": "No",
        //       "unlock": "Unlock",
        //       "ca_doc_sent_date": "2023-08-07",
        //       "faas_non_faas": "Faas",
        //       "view_profile": "Avinash singh",
        //       "join_user_info": {
        //         "invest_today": null,
        //         "investment_budget_per_year": 0,
        //         "expertise_sectors": [
                  
        //         ],
        //         "no_of_investments": null,
        //         "individual_statement_fresher": true,
        //         "individual_statement_intermediate": false,
        //         "individual_statement_experience": false,
        //         "none_of_the_above": false,
        //         "experience": null,
        //         "institution_name": null,
        //         "designation": null,
        //         "institution_url": null,
        //         "institution_assets_worth": null,
        //         "net_worth": 0,
        //         "called_investor": null
        //       }
        //     },
        //     {
        //       "id": 3279,
        //       "demate_account_number": "1019191910303020",
        //       "name": "Avinash Singh",
        //       "nominee_name": "Anay",
        //       "email": "avinash.singh+may258@letsventure.com",
        //       "mobile": "+919584754016",
        //       "linkedin_profile": "https://www.linkedin.com/in/avinash-pratap-singh-13b37a147/",
        //       "city": "Gwalior, India",
        //       "onboard": "angel_investor",
        //       "profile_type": "business_owner",
        //       "kyc_kra_check": "No",
        //       "unlock": "Unlock",
        //       "ca_doc_sent_date": "2023-08-07",
        //       "faas_non_faas": "Faas",
        //       "view_profile": "Avinash singh",
        //       "join_user_info": {
        //         "invest_today": null,
        //         "investment_budget_per_year": 0,
        //         "expertise_sectors": [
                  
        //         ],
        //         "no_of_investments": null,
        //         "individual_statement_fresher": false,
        //         "individual_statement_intermediate": false,
        //         "individual_statement_experience": false,
        //         "none_of_the_above": true,
        //         "experience": null,
        //         "institution_name": null,
        //         "designation": null,
        //         "institution_url": null,
        //         "institution_assets_worth": null,
        //         "net_worth": 0,
        //         "called_investor": null
        //       }
        //     },
            
        //   ]
        // }
        let formattedData = result?.results?.map((item: any) => ({
          ...item,
          // date: item.approval_date,
          // manager: item.relationship_manager,
          // phone_no: item.users_phone,
          location: item.city,
          onboarding_as: item.onboard,
          // type: item.investor_type,
        }));
        return { formattedData, count: result?.count };
      } catch (error) {
        throw error;
      }
    }
  }
  