export const caDocStatus = [
  { label: "Select Status" },
  {
    label: "Sent",
    value: "sent",
  },
  {
    label: "Not Sent",
    value: "notsent",
  },
];

export const faasNonFaasTypes = [
  { label: "Select Type" },
  {
    label: "FaaS",
    value: "faas",
  },
  {
    label: "NON-FaaS",
    value: "non_fass",
  },
  {
    label: "Institutional",
    value: "institutional",
  },
];

export const BreadCrumbDataInvestorInformation = {
  stepKey: 1,
  steps: [
    {
      title: "Dashboard",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Investor Information",
    navigationButton: false,
  },
};

export const BreadCrumbDataNomineeInformation = {
  stepKey: 1,
  steps: [
    {
      title: "Dashboard",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Investor Nominee",
    navigationButton: false,
  },
};

//============================

export const BreadCrumbDataDematInformation = {
  stepKey: 1,
  steps: [
    {
      title: "Dashboard",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Demat Information",
    navigationButton: false,
  },
};

export const DematStatusType = {
  WITH_DEMAT_ACCOUNT: "with_demat",
  WITHOUT_DEMAT_ACCOUNT: "without_demat",
};

export const DematStatusTabs: any = [
  {
    name: "WITH DEMAT ACCOUNT",
    value: DematStatusType.WITH_DEMAT_ACCOUNT,
  },
  {
    name: "WITHOUT DEMAT ACCOUNT",
    value: DematStatusType.WITHOUT_DEMAT_ACCOUNT,
  },
];
