import { useState } from "react";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { HorizontalTabs } from "@trica-equity/ui-library.tabs.horizontal-tabs";
import AwaitingTable from "./awaitingTable";
import RejectedTable from "./rejectedTable";
import { Box, Grid, Stack } from "@mui/material";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { toast } from "react-toastify";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { useEffect } from "react";
import {
  InitialScreeningTabs,
  SortByOptions,
} from "../../constants/InitialScreeningConstant";

export default function CaDocStatus() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const [selectedTab, setSelectedTab] = useState(InitialScreeningTabs[0].value);
  const [sortBy, setSortBy] = useState(SortByOptions[0]);
  const [sortBySectorPoc, setSortBySectorPoc] = useState("");
  const [searchBySector, setSearchBySector] = useState("");
  const [sectorOption, setSectorOption] = useState([]);
  const [searchByStartup, setSearchByStartup] = useState("");
  const [trigger, setTrigger] = useState(false);

  const getSectorOption = async () => {
    try {
      const result = await userManagementManager.getLookupData(
        "",
        "horizontal_sector"
      );
      let options = result?.results?.map((item: any) => ({
        label: item?.text,
        value: item?.id,
      }));
      setSectorOption(options);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "Startup Management",
        titleDisplay: true,
      },
    ],
    pageHeader: {
      header: "Initial Screening",
      navigationButton: false,
    },
  };

  const handleSearch = () => setTrigger((trigger) => !trigger);

  useEffect(() => {
    getSectorOption();
  }, []);

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid
          item
          xs={9}
          display="flex"
          gap={2}
          justifyContent="end"
          // flexWrap="wrap"
        >
          <Box width={"180px"}>
            {" "}
            <CoreDropdown
              label="Sort By"
              options={SortByOptions}
              value={sortBy}
              onClickMenuItem={(value: any) => setSortBy(value)}
            />
          </Box>

          <Box width={"180px"}>
            {" "}
            <CoreDropdown
              label="Sort By Sector POC"
              options={[]}
              value={sortBySectorPoc}
              onClickMenuItem={(value: any) => setSortBySectorPoc(value)}
              clearable
            />
          </Box>
          <Box width={"160px"}>
            <CoreDropdown
              label="Search By Sector"
              options={sectorOption}
              value={searchBySector}
              onClickMenuItem={(value: any) => setSearchBySector(value)}
              clearable
            />
          </Box>

          <Stack direction="row" gap={2}>
            <Box>
              <CustomTextField
                label={"Search By Startup"}
                name="startup_name"
                value={searchByStartup}
                onChange={(val: string) => setSearchByStartup(val)}
              />
            </Box>

            <ActionButton onClick={handleSearch}>Search</ActionButton>
          </Stack>
        </Grid>
      </Grid>

      <div>
        <HorizontalTabs
          tabs={InitialScreeningTabs}
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
          xAxisPadding={false}
          customTabsStyles={{
            width: "100%",
            height: "44px",
          }}
          customTabStyles={{
            fontFamily: "Work Sans",
            lineHeight: "21px",
            textAlign: "center",
            letterSpacing: "0.5px",
            fontStyle: "normal",
          }}
        />
      </div>

      {selectedTab === "awaiting" && (
        <AwaitingTable
          sortBy={sortBy}
          searchBySector={searchBySector}
          searchByStartup={searchByStartup}
          trigger={trigger}
        />
      )}
      {selectedTab === "rejected" && (
        <RejectedTable
          sortBy={sortBy}
          searchBySector={searchBySector}
          searchByStartup={searchByStartup}
          trigger={trigger}
        />
      )}
    </Box>
  );
}
