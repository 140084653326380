import { FC, useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CommonModal } from "@trica-equity/ui-library.modals.common-modal";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { FileUpload } from "@trica-equity/ui-library.form-elements.file-upload";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../../../../../contexts/authContext";
import Loader from "../../../../loader/loading";
import { useStyles } from "../../dealDocuments/styles";
import { toast } from "react-toastify";
import { DocumentTypeNames } from "../../../constants/documentConstants";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";
import UserManagementManager from "../../../../userManagement/managers/userManagementManager";
import DocumentManagement from "../../../managers/documentManager";

type ReactUploadInvestorDocumentModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
};

const UploadInvestorDocumentModal: FC<ReactUploadInvestorDocumentModalType> = (props) => {
  const { isOpen, handleClose } = props;
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const documentManagementManager = new DocumentManagement(authContext);
  const [files, setFiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUpdate, setSelectedUpdate] = useState<any>(null);
  const [notes, setNotes] = useState<any>("");

  const [selectedNameEmail, setSelectedNameEmail] = useState<any>({});
  const [nameEmailOptions, setNameEmailOptions] = useState<any>([]);
  const [selectedInvestmentProfile, setSelectedInvestmentProfile] =
    useState<any>({});
  const [investmentProfileOptions, setInvestmentProfileOptions] = useState<any>(
    []
  );

  const DocumentCategoryOptionsExtended = [
    { label: "All", value: "all" },
    ...DocumentTypeNames,
  ];

  const handleInvestorSearch = async (value: any) => {
    console.log("selectedNameEmail?.value", selectedNameEmail?.value);
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "only_investor_users"
      );
      console.log("result?.results", result?.results);
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setNameEmailOptions(result);
    } catch (err) {
      console.log(err);
    }
  };

  const handleProfileSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getInvestmentProfile(
        value,
        selectedNameEmail?.value
      );
      result = result?.data.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setInvestmentProfileOptions(result);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const data = {
        user_id: selectedNameEmail?.value,
        investor_information_id: selectedInvestmentProfile?.value,
        document_title: selectedUpdate?.value,
        document_type: "",
        document_url: files[0],
        document_notes: notes,
        year: "",
        quarter: "",
      };

      const result = await documentManagementManager.uploadDocument(data);
      toast.success("Document Uploaded Successfully");
      setIsLoading(false);
      handleClose();
    } catch (error: any) {
      toast.error(error.message ?? "Something Went Wrong");
      setIsLoading(false);
    }
  };


  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title="Upload Investor Document"
      submitText="&nbsp;&nbsp;Submit&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Close&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      <ToastContainer />
      <Box width="50vw">
        {isLoading && <Loader />}

        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Investor Name or Email
            </Grid>
            <Grid item xs={8}>
              <div 
                // className={classes.fieldItem} 
                style={{ marginTop: "5px" }}>
                <LvAutoComplete
                  label="Investor Name or Email"
                  options={nameEmailOptions}
                  setOptions={setNameEmailOptions}
                  selectedObject={selectedNameEmail}
                  setSelectedObject={setSelectedNameEmail}
                  onChange={handleInvestorSearch}
                  onSelect={() => setNameEmailOptions([])}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Investment Profile
            </Grid>
            <Grid item xs={8}>
              <div 
              // className={classes.fieldItem} 
              style={{ marginTop: "5px" }}>
                <LvAutoComplete
                  label="Investment Profile"
                  options={investmentProfileOptions}
                  setOptions={setInvestmentProfileOptions}
                  selectedObject={selectedInvestmentProfile}
                  setSelectedObject={setSelectedInvestmentProfile}
                  onChange={handleProfileSearch}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Type of File
            </Grid>
            <Grid item xs={8}>
              <Box width={"190px"}>
                <CoreDropdown
                  label="Choose Update Type"
                  options={DocumentCategoryOptionsExtended}
                  value={selectedUpdate}
                  onClickMenuItem={(value: any) => setSelectedUpdate(value)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Select File
            </Grid>
            <Grid item xs={8}>
              <FileUpload
                customStyles={{ maxWidth: 550 }}
                placeholder="select a file"
                helperText="upload your file here"
                selectedFile={(file: any) => setFiles(file)}
                multiple
                value={files}
                accept={{ "image/svg": [".png", ".svg"], "text/pdf": [".pdf"] }}
                title="File Upload Dialog Box"
                hideDocumentVault
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Notes
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                  label="Notes"
                  width="100%"
                  placeholder="Notes"
                  name="notes"
                  id="notes"
                  multiline
                  minRows={4}
                  value={notes}
                  onChange={(val: any) => setNotes(val)}
                />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default UploadInvestorDocumentModal;