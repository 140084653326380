import { useState, useEffect, useContext } from "react";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { API_STATUS, CsvFilename } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { NoDataAvailable } from "@trica-equity/ui-library.error.no-data-available";
import { IconConstant } from "../../../../constants/iconConstantss";
import { isEmpty } from "../../../../utils/commonUtils";
import DocumentManagement from "../../managers/documentManager";
import UploadDocumentModal from "./uploadDocumentModal";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import makeStyles from "../../../styles";
import { AuthContext } from "../../../../contexts/authContext";
import { Box, Grid, Stack } from "@mui/material";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import {
  BreadCrumbDataDealDocument,
  DocumentCategoryOptions,
  DocumentNameOptions,
} from "../../constants/documentConstants";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import UnpublishModal from "./unPublishModal";
import PublishModal from "./publishModal";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

export default function DealDocuments() {
  const DocumentCategoryOptionsExtended = [
    { label: "All", value: "all" },
    ...DocumentCategoryOptions,
  ];
  const [openUnpublishModal, setOpenUnpublishModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);

  const [selected, setSelected] = useState<any>();

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);
  const [page, setPage] = useState(1);

  const classes = makeStyles();
  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] =
    useState(false);
  const [downloading, setDownloading] = useState(false);
  const [documentCategory, setDocumentCategory] = useState<any>({});
  const [documentName, setDocumentName] = useState<any>({});

  const [selectedDeal, setSelectedDeal] = useState<any>({});
  const [dealOptions, setDealOptions] = useState<any>([]);
  const handleSearchDealName = async (value: string) => {
    try {
      let result = await documentManagementManager.searchDealName(value);
      result = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setDealOptions(result);
    } catch (error) {
      console.log(error);
    }
  };

  const viewFile = (url: string) => {
    window.open(url, "_blank");
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 100,
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "formated_document_category",
      headerName: "Document Category",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "formated_document_name",
      headerName: "Document Name",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "last_updated_by",
      headerName: "Last Updated By",
      sortable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: "last_updated_date",
      headerName: "Last Updated Date",
      sortable: false,
      flex: 2,
      minWidth: 180,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 2,
      minWidth: 300,

      renderCell: (params: any) => {
        return (
          <Stack direction="row" gap={3}>
            {params.row?.document_url && (
              <OutlinedButton
                customStyles={{
                  color: getColor("action_button", 200),
                  borderColor: getColor("action_button", 200),
                }}
                onClick={() => {
                  viewFile(params.row?.document_url);
                }}
              >
                View
              </OutlinedButton>
            )}
            {params.row.document_category === "execution_documents" && (
              <OutlinedButton
                customStyles={{
                  color: params.row?.publish
                    ? getColor("error", 500)
                    : getColor("success", 500),
                  borderColor: params.row?.publish
                    ? getColor("error", 500)
                    : getColor("success", 500),
                  padding: params.row?.publish ? "auto" : "0 26px",
                }}
                onClick={() => {
                  setSelected(params.row);
                  params.row?.publish
                    ? setOpenUnpublishModal(true)
                    : setOpenPublishModal(true);
                }}
              >
                {params.row?.publish ? "Unpublish" : "Publish"}
              </OutlinedButton>
            )}
          </Stack>
        );
      },
    },
  ];

  const getDealDocuments = async (
    currentPage = 1,
    deal_id?: string,
    document_category?: string,
    document_name?: string
  ) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await documentManagementManager.getDealDocuments(
          currentPage,
          deal_id,
          document_category === "all" ? "" : document_category,
          document_name === "all" ? "" : document_name
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getDealDocuments(page + 1);
  };

  useEffect(() => {
    getDealDocuments();
  }, []);

  const handleDownloadDocument = async () => {
    try {
      setDownloading(true);
      const result: any = await documentManagementManager.downloadDealDocument(
        page,
        selectedDeal?.value ?? "",
        documentCategory?.value ?? "",
        documentName?.value ?? ""
      );
      var a = document.createElement("a");
      a.href = "data:attachment/csv;charset=utf-8," + encodeURI(result.data);
      a.target = "_blank";
      a.download = `${CsvFilename.STARTUP_DOCUMENT}.csv`;
      document.body.appendChild(a);
      a.click();
      setDownloading(false);
    } catch (e: any) {
      toast.error(e);
      setDownloading(false);
    }
  };

  const handleSearch = () => {
    getDealDocuments(
      1,
      selectedDeal?.value,
      documentCategory?.value,
      documentName?.value
    );
  };
  const reloadData = () => {
    getDealDocuments(page);
  };

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <div>
        <NoDataAvailable
          title="Error"
          subTitle={"Something went wrong"}
          image={IconConstant.DATA_NOT_FOUND_ICON}
        />
      </div>
    );
  }

  const handleUploadDocumentModal = () => {
    setIsUploadDocumentModalOpen(false);
  };

  return (
    <Box p={2}>
      <UnpublishModal
        isOpen={openUnpublishModal}
        handleClose={() => setOpenUnpublishModal(false)}
        selected={selected}
        reloadData={reloadData}
      />
      <PublishModal
        isOpen={openPublishModal}
        handleClose={() => setOpenPublishModal(false)}
        selected={selected}
        reloadData={reloadData}
      />

      <Grid container>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataDealDocument?.steps}
            pageHeader={BreadCrumbDataDealDocument.pageHeader}
            stepKey={BreadCrumbDataDealDocument.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <ActionButton
            isLoading={downloading}
            onClick={handleDownloadDocument}
            // customStyles={{ padding: "11px 50px" }}
          >Download Document CSV</ActionButton>
          <ActionButton onClick={() => setIsUploadDocumentModalOpen(true)}>Upload Document</ActionButton>
          <Box>
            <LvAutoComplete
              label="Enter Deal Name"
              options={dealOptions}
              setOptions={setDealOptions}
              selectedObject={selectedDeal}
              setSelectedObject={setSelectedDeal}
              onSelect={() => setDealOptions([])}
              onChange={handleSearchDealName}
            />
          </Box>
          <Box width={"190px"}>
            <CoreDropdown
              label="Document Category"
              options={DocumentCategoryOptionsExtended}
              value={documentCategory}
              onClickMenuItem={(value: any) => setDocumentCategory(value)}
            />
          </Box>
          <ActionButton
            onClick={handleSearch}
          >
            Search
          </ActionButton> 
        </Grid>
      </Grid>

      <div>
        {apiStatus === API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <TeComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            filterMode="server"
            leftPinnedColumns={["name"]}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
      {isUploadDocumentModalOpen && (
        <UploadDocumentModal
          openModal={isUploadDocumentModalOpen}
          handleModal={handleUploadDocumentModal}
        />
      )}
    </Box>
  );
}
